import axios from 'axios';
import router from '../router'

// 线上
axios.defaults.baseURL = 'https://app.jxxwgc.org.cn/api/'
// 测试
// axios.defaults.baseURL = 'http://joint.jxxwgc.org.cn/api/'
axios.defaults.withCredentials = true  //允许axios在请求的的过程中携带cookie凭证
axios.defaults.headers['X-Requested-with'] = 'XMLHttpRequest' //开启axios在浏览器中的能力
axios.defaults.headers['token'] = localStorage.getItem('token') || '' //登录成功后携带token
axios.defaults.headers.post['Content-Type'] = 'application/json'  //让post默认返回JSON类型

// 拦截后端返回的响应数据
// axios.interceptors.response.use(res => {
//   if (typeof res.data !== 'object') {
//     showFailToast('服务器异常')
//     return Promise.reject(res)
//   }
//   if(res.data.resultCode != 200) {
//     if (res.data.message) showFailToast(res.data.message)
//     if (res.data.resultCode == 416) { //登录失效
//       router.push('/login')
//     }
//     return Promise.reject(res.data)
//   }
//   return res.data //正常
// })

export default axios