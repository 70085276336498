// 封装一个函数,方便首页直接调用这个函数就能拿到数据
import axios from '../axios.js'

const headers = {
  'os': 'PC'
}
const data = {

}
export function getCarousel(token) {
  return axios.post('bannerIndex/', data, {
    headers
    }
  )
}

export function getContributionList(token) {
  return axios.post('list/', {"limit":6,"og_id":0}, {
    
  })
}

export function getRecentlyContribution() {
  return axios.post('indexHomeShow/')
}

export function getOfflineContribution() {
  return axios.post('get_offline_contribution_xx/')
}