import axios from '../axios.js'

export function getColumns(id) {
  return axios.post('getArticleNewTypeList/', {
    "type_id": id
  })
}

export function getNewsList(type, tagType, items, page, keyword) {
  return axios.post('getArticleList/',{
    "type": type,
    "tag_type": tagType,
    "limit": items,
    "page": page,
    "keyword": keyword
  })
}