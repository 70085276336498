import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// import News from '../views/News.vue'
// import newsList from '../views/newsList.vue'
// import contributionList from '../views/contributionList.vue'
// import contribution from '../views/contribution.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import('../views/newsList.vue'),
    meta: {
      title: '新闻动态'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/news/',
    name: 'news',
    component: () => import('../views/News.vue'),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/contributionList',
    name: 'contributionList',
    component: () => import('../views/contributionList.vue'),
    meta: {
      title: '公益项目'
    }
  },
  {
    path: '/contribution',
    name: 'contribution',
    component: () => import('../views/contribution.vue'),
    meta: {
      title: '捐赠详情'
    }
  },
  {
    path: '/Form',
    name: 'Form',
    component: () => import('../views/Form.vue'),
    meta: {
      title: '表单'
    }
  },
  {
    path: '/searchDonate',
    name: 'searchDonate',
    component: () => import('../views/searchDonate.vue'),
    meta: {
      title: '搜索捐赠记录'
    }
  },
  {
    path: '/searchNews',
    name: 'searchNews',
    component: () => import('../views/searchNews.vue'),
    meta: {
      title: '搜索新闻动态'
    }
  },
  {
    path: '/searchContribution',
    name: 'searchContribution',
    component: () => import('../views/searchContribution.vue'),
    meta: {
      title: '搜索捐赠项目'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
