<template>
  <div class="home">
    <div class="block text-center">
      <el-carousel height="100vh" indicator-position="">
        <el-carousel-item v-for="item in $store.state.carousel" :key="item">
          <a :href="item.location" :target="item.opentype">
            <img
              :src="item.thumb"
              alt="图片加载失败"
              style="object-fit: cover"
            />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <!-- <Float class="float" title="用户信息" /> -->
      <Title class="Title" text="最新捐赠" />
      <div class="container-box1">
        <el-card
          v-for="(item, index) in store.state.homeContributionList"
          :key="index"
          @click="goContribution(item.item_id, item.stage)"
          :body-style="{ padding: 0 }"
          class="contribution-card"
        >
          <img :src="item.item_cover_img" class="image" />
          <div class="info">
            <span>{{ item.item_name }}</span>
            <el-button text class="button">{{ item.is_raising }}</el-button>
            <div class="bottom">
              <time class="time"
                >已筹款 <span v-if="item.item_id == 96">{{ (parseFloat(item.money_sum * 100) / 100 + parseFloat(state.lx_total * 100) / 100).toFixed(2) }}</span><span v-else>{{ item.money_sum }}</span>元</time
              >
            </div>
          </div>
        </el-card>
      </div>
      <Title class="Title" text="累计捐赠收入" />
      <!-- <div class="sum">￥{{ $store.state.sum }}元</div> -->
      <div class="sum">￥10.50亿元</div>
      <div class="bg">
        <div class="container-box2">
          <div class="line">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <el-row>
            <el-col v-for="item in state.sumList" :key="item.id" :span="6">
              <el-card :body-style="{ padding: 0 }" class="sum-card">
                <img :src="item.cover" class="image" />
                <div style="padding: 14px">
                  <span>{{ item.title }}</span>
                  <div class="bottom">
                    <time class="time">{{ item.num }}</time>
                    <el-button text class="button">{{ item.unit }}</el-button>
                  </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="container-box3">
        <Title class="Title" text="最新捐赠信息与查询" />
        <div class="search-bar">
          <input
            type="text"
            v-model="state.searchPerson"
            placeholder="请输入姓名查询捐赠单号"
          />
          <img
            class="search"
            src="@/assets/images/home/search.png"
            alt="图片加载失败"
            @click="searchDonate(state.searchPerson)"
          />
        </div>
        <div class="info-box">
          <div class="info-card1">
            <p>
              上一年度募资总额: <span>{{ $store.state.lastYearSum }}</span
              >元
            </p>
            <p>
              上一月度募资总额: <span>{{ $store.state.lastMonthSum }}</span
              >元
            </p>
            <el-button plain>项目信息公开</el-button>
            <el-button class="report" type="primary">年度审计报告</el-button>
          </div>
          <div class="info-card2">
            <img src="@/assets/images/home/aaa.png" alt="" />
            <el-input
              v-model="state.input"
              class="w-50 m-2"
              placeholder="搜索公益项目"
            />
            <el-button type="danger" @click="searchItem(state.input)"
              >搜索项目</el-button
            >
          </div>
          <div class="info-card3">
            <el-card
              class="box-card"
              shadow="none"
              :body-style="{ padding: 0 }"
            >
              <div class="card-header">
                <span>近期捐赠</span>
              </div>
              <el-tabs
                v-model="state.tab"
                class="demo-tabs"
                @tab-click="handleClick"
              >
                <el-tab-pane label="线上捐" :name="1">
                  <el-carousel
                    height="360px"
                    arrow="never"
                    direction="vertical"
                    :interval="3000"
                  >
                    <el-carousel-item>
                      <div
                        v-for="item in store.state.recentlyContribution.slice(
                          0,
                          10
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.user_name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.time }}</span>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item
                      v-if="store.state.recentlyContribution.length > 10"
                    >
                      <div
                        v-for="item in store.state.recentlyContribution.slice(
                          10,
                          20
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.user_name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.time }}</span>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item
                      v-if="store.state.recentlyContribution.length > 20"
                    >
                      <div
                        v-for="item in store.state.recentlyContribution.slice(
                          20,
                          30
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.user_name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.time }}</span>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item
                      v-if="store.state.recentlyContribution.length > 30"
                    >
                      <div
                        v-for="item in store.state.recentlyContribution.slice(
                          30,
                          40
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.user_name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.time }}</span>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </el-tab-pane>
                <el-tab-pane label="线下捐" :name="2">
                  <el-carousel
                    height="360px"
                    arrow="never"
                    direction="vertical"
                    :interval="3000"
                  >
                    <el-carousel-item>
                      <div
                        v-for="item in store.state.offlineContribution.slice(
                          0,
                          10
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.create_time }}</span>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item
                      v-if="store.state.offlineContribution.length > 10"
                    >
                      <div
                        v-for="item in store.state.offlineContribution.slice(
                          10,
                          20
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.create_time }}</span>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item
                      v-if="store.state.offlineContribution.length > 20"
                    >
                      <div
                        v-for="item in store.state.offlineContribution.slice(
                          20,
                          30
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.create_time }}</span>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item
                      v-if="store.state.offlineContribution.length > 30"
                    >
                      <div
                        v-for="item in store.state.offlineContribution.slice(
                          30,
                          40
                        )"
                        :key="item"
                        class="text item"
                      >
                        <span>{{ item.name }}</span>
                        <span>{{ item.money }}元</span>
                        <span>{{ item.create_time }}</span>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </div>
        </div>
      </div>
      <div class="container-box4">
        <div class="table">
          <div
            :class="state.news ? 'select' : 'table-item'"
            @click="select(18)"
          >
            新闻资讯
          </div>
          <div
            :class="state.info ? 'select' : 'table-item'"
            @click="select(29)"
          >
            信息公开
          </div>
        </div>
        <ul v-if="state.newsList.length" class="news-box">
          <li
            v-for="item in state.newsList"
            :key="item.id"
            @click="goNews(item.id)"
          >
            <template v-if="item.thumb">
              <img :src="item.thumb" alt="" />
            </template>
            <template v-else>
              <img src="../assets/images/news/news4.png" alt="" />
            </template>
            <div class="content">
              <span class="title">{{ item.title }}</span>
              <div class="desc">{{ item.description }}</div>
              <span class="time">{{
                timestampToDate(item.publishtime * 1000)
              }}</span>
            </div>
          </li>
        </ul>
        <div v-else class="info">
          <div class="info-box">该栏目下暂无内容</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Title from "@/components/Title.vue";
import Float from "@/components/Float.vue";
import { reactive, onMounted } from "vue";
import {
  getCarousel,
  getContributionList,
  getRecentlyContribution,
  getOfflineContribution,
} from "../api/service/home.js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getNewsList } from "@/api/service/newsList.js";
import { Search } from "@element-plus/icons-vue";
import axios from "axios";

const store = useStore();
// console.log(store)
const router = useRouter();

const state = reactive({
  sumList: [
    {
      id: 1,
      title: "救助大病青少年儿童 ",
      cover: require("@/assets/images/home/aixin.png"),
      num: 2000,
      unit: "人次",
    },
    {
      id: 1,
      title: "援建希望小学 ",
      cover: require("@/assets/images/home/xuexiao-lvse.png"),
      num: 1512,
      unit: "所",
    },
    {
      id: 1,
      title: "资助困难学生 ",
      cover: require("@/assets/images/home/xueshengziping.png"),
      num: 30,
      unit: "万",
    },
    {
      id: 1,
      title: "援建希望图书馆 ",
      cover: require("@/assets/images/home/tushu.png"),
      num: 2367,
      unit: "个",
    },
    {
      id: 1,
      title: "安排音体美支教 ",
      cover: require("@/assets/images/home/yinledingzhi.png"),
      num: 42545,
      unit: "人次",
    },
    {
      id: 1,
      title: "培训希望小学教师 ",
      cover: require("@/assets/images/home/jiaoshiliebiao.png"),
      num: 12414,
      unit: "人次",
    },
    {
      id: 1,
      title: "获得各类奖项",
      cover: require("@/assets/images/home/jiangbei.png"),
      unit: "荣誉",
    },
  ],
  newsList: [],
  searchPerson: null,
  input: null,
  news: true,
  info: false,
  tab: 1,
  lx_total: null
});

onMounted(() => {
  store.state.column = "/";
  getCarousel()
    .then((response) => {
      store.state.carousel = response.data.res.bannerIndex;
      // console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
  getContributionList()
    .then((response) => {
      store.state.homeContributionList = response.data.res.data;
      store.commit('watchStage', store.state.homeContributionList);
      // console.log(response);
    })
    .catch((error) => {
      console.error(error);
    });
  axios
    .get("https://app.jxxwgc.org.cn/linxi/")
    .then((response) => {
      state.lx_total = response.data.lx_total;
    })
    .catch((error) => {
      console.error(error);
    });
  getRecentlyContribution()
    .then((response) => {
      // console.log(response);
      // console.log(response.data.res, '近期捐赠');
      store.state.recentlyContribution =
        response.data.res.donationNumber.people;
      store.state.sum = response.data.res.donationNumber.moneyNumber;
      // console.log(store.state.recentlyContribution);
    })
    .catch((error) => {
      console.error(error);
    });
  getOfflineContribution()
    .then((response) => {
      console.log(response);
      store.state.offlineContribution = response.data.res.data;
      console.log(store.state.offlineContribution);
      // store.state.sum = response.data.res.donationNumber.moneyNumber;
    })
    .catch((error) => {
      console.error(error);
    });
  getNewsList(18, "", 6, 1)
    .then((response) => {
      // console.log(response, "新闻列表");
      state.newsList = response.data.res.data;
    })
    .catch((error) => {
      console.error(error);
    });
});

store.commit("watchType");

// const watchState = (e) => {
//   if (!e) return "募集中";
//   return "募集成功";
// };
const watchStage = (stage) => {
	switch (stage) {
		case 1:
			return '筹款中';
			break;
		case 2:
			return '暂停筹款';
			break;
		case 3:
			return '终止筹款';
			break;
		case 4:
			return '已结项';
			break;
		default:
			break;
	}
};

const watchType = (type) => {
  if (type == 1) return "线上捐";
  return "线下捐";
};

const now = (oldTime) => {
  let newTime = Date.parse(new Date());
  let pastTime = (newTime - oldTime) / 1000;
  switch (true) {
    case pastTime > 8640:
      return Math.ceil(pastTime / 60 / 60 / 24) + "天";
      break;
    case pastTime > 360:
      return Math.ceil(pastTime / 60 / 60) + "小时";
      break;
    case pastTime > 60:
      return Math.ceil(pastTime / 60) + "分钟";
      break;
    default:
      return pastTime + "秒";
  }
};

const searchContribution = () => {};

const timestampToDate = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const select = (value) => {
  switch (value) {
    case 18:
      state.news = true;
      state.info = false;
      break;
    case 29:
      state.info = true;
      state.news = false;
      break;
  }
  getNewsList(value, "", 6, 1)
    .then((response) => {
      // console.log(response, "新闻列表");
      state.newsList = response.data.res.data;
      state.total = response.data.res.total;
    })
    .catch((error) => {
      console.error(error);
    });
};

const goContribution = (id, stage) => {
  // router.push({ path: "/contribution", query: { id, stage } });
  router.push({ path: "/contribution", query: { id } });
};

const goNews = (id) => {
  router.push({ path: "/news", query: { id } });
};

const searchDonate = (value) => {
  router.push({ path: "/searchDonate", query: { value } });
};

const searchItem = (value) => {
  router.push({ path: "/searchContribution", query: { value } });
};
</script>

<style scoped lang="less">
@import "@/assets/css/home.less";
</style>

<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}
.el-carousel__item {
  background-color: none !important;
}
.el-carousel__item img {
  width: 100% !important;
  height: 100% !important;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: none !important;
}
:deep(.el-carousel__button) {
  width: 5vw !important;
  height: 0.5vh !important;
  margin-bottom: 2vh;
}
:deep(.el-carousel__arrow) {
  width: 5vw !important;
  height: 11vh !important;
}
:deep(svg),
:deep(.el-icon) {
  width: 2vw;
  height: 4.3vh;
}
</style>